<footer class="footer">
    <div *ngIf="!securityGroup" class="container px-0 py-0">
        <div class="content row">
            <div class="col-xs-12 col-md-8">
                TOOLS : <span class="d-none d-lg-inline-block"> Predefined list of orders at your fingertips :</span>
                <a data-target="#incotermsImg" data-toggle="modal" data-dismiss="modal"> INCOTERMS </a>
                <a data-target="#shippingContact" data-dismiss="modal" data-toggle="modal"> Shipping Group Organizations
                    with Contact </a>
            </div>
            <div class="col-xs-12 col-md-4">
                <span class="position-fixed end-0 mr-5">© 1995-2022 TOYOTA MOTOR CORPORATION. All Rights
                    Reserved.</span>
            </div>
        </div>
    </div>
</footer>

<div class="modal fade text-dark" id="shippingContact" tabindex="-1" role="dialog" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title text-center">
                    Shipping Group Organizations with Contact
                </div>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 col-lg-12 text-center" *ngIf="plantCode == 'TTC'">
                            <span class="shippingContactHeader">Email for TTC Shipping Department</span><br>
                            <span class="shippingContactInfo mt-1">shipping.ttc@tema.toyota.com</span><br><br>
                            <span><a style="color: #cc0000;" target="_blank"
                                    href="https://teams.microsoft.com/l/channel/19%3af2c8981b67304d499172e6148d369ef6%40thread.tacv2/General?groupId=097ac4e9-abd4-45bc-a6d7-56857d269f98&tenantId=8c642d1d-d709-47b0-ab10-080af10798fb">
                                    Click here to contact Shipping Group!</a></span>
                        </div>
                        <div class="col-md-12 col-lg-12 text-center" *ngIf="plantCode == 'TMMI'">
                            Phone Number for TMMI Shipping Department<br>
                            812-387-3223
                        </div>
                        <div class="col-md-12 col-lg-12" *ngIf="plantCode == 'TMMMS'">
                            <b>Email : </b><span
                                class="shippingContactInfo">GeneralStores-PC@internal.toyota.com</span><br>
                            <span><b>Shipping Desk # : </b></span> 662-317-3100
                        </div>
                        <div class="col-md-12 col-lg-12 p-0" *ngIf="plantCode == 'TMMK'">
                            <b>Email : </b><span
                                class="shippingContactInfo">raw.materials.shipping.editors@internal.tema.toyota.com</span><br>
                            <div class="mt-2"><b>Phone : </b>502-868-3812</div>
                        </div>
                        <div class="col-md-12 col-lg-12 p-0" *ngIf="plantCode == 'TMMAL'">
                            <b>General Stores Phone # : </b>256-746-5616
                        </div>
                        <div class="col-md-12 col-lg-12 p-0" *ngIf="plantCode == 'HQ'">
                            <b>Email : </b>PlanoMailroom@toyota.com
                        </div>
                        <div class="col-md-12 col-lg-12 p-0" *ngIf="plantCode == 'TMMWV'">
                            <div class="mt-2">
                                <li>Patrick.ball@toyota.com (304-937-7656)</li>
                                <li>Todd.parrish@toyota.com</li>
                                <li>John.standish@toyota.com</li>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-12 text-center"
                            *ngIf="plantCode != 'TMMI' && plantCode != 'TTC' && plantCode != 'TMMMS' && plantCode!='TMMK'  && plantCode!='TMMAL'  && plantCode!='TMMWV' && plantCode!='HQ'">
                            Contact information is not available. Please contact MSRS_Support team for guidance.
                        </div>
                        <div class="d-flex flex-row justify-content-center align-items-center mt-3">
                            <button type="button" class="next-button ml-4" data-dismiss="modal">
                                <div class="mx-auto">Close</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade text-dark" id="incotermsImg" tabindex="-1" role="dialog" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <span class="material-icons incotermsClose" title="Close"
                            data-dismiss="modal">highlight_off</span>
                        <img class="incotermsImage" src="../../../assets/Incoterms.png" alt="Incoterms">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>