import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment'
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  getNotificationURL = environment.notificationURL + "getNotification?plantCode="
  updateNotificationURL = environment.notificationURL + "updateIsReadNotification"
  constructor(private readonly _http: HttpClient) { }
  getNotification(plantCode, userId, isRead) {
    if (isRead == '') {
      var url = this.getNotificationURL + plantCode + '&userId=' + userId;
    }
    else {
      var url = this.getNotificationURL + plantCode + '&userId=' + userId + '&isRead=' + isRead;
    }
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  updateNotification(notifyObj) {
    const url = this.updateNotificationURL
    return this._http.post(url, notifyObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      errorMsg = `Backend returned code ${error.status}`;
    }
    // return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
