import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  printFormURL = environment.notificationURL + "printForm?requestId=";
  printFormBulkURL = environment.notificationURL + "printFormBulkInsert?requestId=";
  tokenURL = environment.tokenURL;
  isEligibleForApproveURL = environment.createRequestURL + "isEligibleForApprove?userId=";

  reportArray = {};
  securityGroup: boolean;
  reportsAdmin:boolean;
  scrapShipping:boolean;
  constructor(private http: HttpClient, private readonly spinner: NgxSpinnerService, private readonly toastrService: ToastrService) { }

  setSecurityGroup(value){
    this.securityGroup = value;
  }

  getSecurityGroup(){
    if(this.securityGroup == undefined){
      if(localStorage.getItem('roleType') == 'security group'){
        this.securityGroup = true;
      }
      else{
        this.securityGroup = false;
      }
    }

    return this.securityGroup;
  }

  setReportsAdmin(value){
    this.reportsAdmin = value;
  }

  getReportsAdmin(){
    if(this.reportsAdmin == undefined){
      if(localStorage.getItem('roleType') == 'reports admin'){
        this.reportsAdmin = true;
      }
      else{
        this.reportsAdmin = false;
      }
    }

    return this.reportsAdmin;
  }

  getReportDetails() {
    return this.reportArray
  }
  setReportDetails(reportArray) {
    this.reportArray = reportArray
    console.log(this.reportArray)
  }

  setScrapShipping(value){
    this.scrapShipping = value;
  }

  getScrapShipping(){
    if(this.scrapShipping == undefined){
      if(localStorage.getItem('isScrap') == 'scrap shipping'){
        this.scrapShipping = true;
      }
      else{
        this.scrapShipping = false;
      }
    }

    return this.scrapShipping;
  }
  async getTokens(tokenBody){
    let isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
        })
      };
    }
    return await this.http.post(this.tokenURL,tokenBody, httpOptions)
      .map((response: any) => response)
      .catch(this.handleError).toPromise();
  }
  
  printRequest(requestid,printType) {
    console.log(requestid);
    const url = this.printFormURL + requestid+"&printType="+printType;
    return this.http.get(url, httpOptions)
      .map((response: any) => response)
      .catch(this.handleError)    
  }
  printRequestBulkItem(requestid,printType) {
    console.log(requestid);
    const url = this.printFormBulkURL + requestid+"&printType="+printType;
    return this.http.get(url, httpOptions)
      .map((response: any) => response)
      .catch(this.handleError)    
  }

  isEligibleForApprove(userId) {
    const url = this.isEligibleForApproveURL + userId
    return this.http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  private handleError(error: HttpErrorResponse) {
    console.log("error",error)
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      errorMsg = `Backend returned code ${error.status}`;
    }
    // return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
