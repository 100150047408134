import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  goBack() {
    localStorage.clear();
    window.location.href = `https://login.microsoftonline.com/common/oauth2/logout`; 
  }
}
