import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class ActivityStreamService {
  getActivityStreamURL = environment.notificationURL + "getActivityStream?requestId=";
  addActivityStreamUrl = environment.notificationURL + "addActivityStream";

  constructor(private readonly _http: HttpClient) { }

  getActivityStream(requestId): Observable<any> {
    const url = this.getActivityStreamURL+requestId;
    return this._http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  addActivityStream(activityStreamObj): Observable<any> {
    const url = this.addActivityStreamUrl;
    return this._http.post(url, activityStreamObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }

  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      errorMsg = `Backend returned code ${error.status}`;
    }
    // return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
