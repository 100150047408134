import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment'
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Dec 2001 00:00:00 GMT',
      })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class HomeService {
  addFavoritesURL = environment.dashboardURL + "addFavorites";
  updateFavoritesURL = environment.dashboardURL+"updateFavorites";
  getFavoritesURL = environment.dashboardURL+"getFavorites?userId=";
  getSavedReportURL = environment.reportUrl+"getSavedReport?userId=";
  deleteSavedReportsURL = environment.reportUrl+"deleteSavedReport";
  constructor(private readonly _http: HttpClient) { }
  addFavorites(favObj) {
    const url = this.addFavoritesURL;
    return this._http.post(url, favObj ,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  updateFavorites(favObj) {
    const url = this.updateFavoritesURL;
    return this._http.post(url, favObj ,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getFavorites(userId,plantCode) {
    const url = this.getFavoritesURL + userId + '&plantCode=' + plantCode;
    return this._http.get(url ,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getSavedReports(userId,plantCode){
    const url = this.getSavedReportURL + userId + '&plantCode=' + plantCode;
    return this._http.get(url ,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  deleteSavedReports(deleteObj){
    const url = this.deleteSavedReportsURL;
    return this._http.post(url, deleteObj ,httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      errorMsg = `Backend returned code ${error.status}`;
    }
    // return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
