import * as CryptoJS from 'crypto-js';
var cv = '';
function generateRandomString(length) {
  var text = '';
  let codeVerifier = localStorage.getItem('code_verifier');
  if (codeVerifier !== null && codeVerifier !== undefined && codeVerifier !== '') {
    cv = codeVerifier;
    return codeVerifier;
  } else {
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    cv = text;
    localStorage.setItem('code_verifier', cv);
    return text;
  }
}

function generateCodeChallenge(codeVerifier) {
  const codeChallange = base64URL(CryptoJS.SHA256(codeVerifier));
  return codeChallange + '&code_challenge_method=S256';
}

function base64URL(string: any) {
  return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const environment = {
  production: false,
  baseLoginUrl : 'https://login.microsoftonline.com/8c642d1d-d709-47b0-ab10-080af10798fb/oauth2/v2.0/authorize?client_id=4096ed03-ffa5-4916-a5ee-ecfff0057bc8&response_type=code&redirect_uri=https://msrs.toyota.com&response_mode=query&scope=openid&state=23343&code_challenge=',
  addressURL:'https://prodapi.msrs.toyota.com/prod_address/api/',
  shippingURL:'https://prodapi.msrs.toyota.com/prod_manageShipping/api/',
  createRequestURL:'https://prodapi.msrs.toyota.com/prod_createRequest/api/',
  reportUrl:'https://prodapi.msrs.toyota.com/prod_report/api/',
  viewRequestURL:'https://prodapi.msrs.toyota.com/prod_viewRequest/api/',
  notificationURL: 'https://prodapi.msrs.toyota.com/prod_notification/api/',
  dashboardURL: 'https://prodapi.msrs.toyota.com/prod_dashboard/api/',
  partsSearchURL: 'https://prodapi.msrs.toyota.com/prod_gtsparts/api/',
  signedUrl: 'https://t12jd2yrhc.execute-api.us-east-1.amazonaws.com/prod/api',
  tokenURL: 'https://login.microsoftonline.com/8c642d1d-d709-47b0-ab10-080af10798fb/oauth2/v2.0/token',
  logoutUrl: 'https://login.microsoftonline.com/common/oauth2/logout',
  grantType: 'authorization_code',
  clientId: '4096ed03-ffa5-4916-a5ee-ecfff0057bc8',
  redirectURI : 'https://msrs.toyota.com',
  codeVerifier: generateRandomString(128),
  codeChallenge: generateCodeChallenge(cv)
};
