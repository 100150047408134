<div class="" *ngIf="this.plantCode && this.userId && this.userName">
        <!-- <button class="navbar-toggler d-block d-md-none" type="button" data-toggle="collapse" data-target="#mySidebar"
        aria-controls="mySidebar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
</button> -->
        <div id="mySidebar" class="sidebar">
                <a href="/" class="navbar-brand text-uppercase logoTextSize">
                        <img src="/assets/Logo.png" height="34" alt="Toyota Logo" />
                        <span class="title">Miscellaneous Shipping</span><br>
                        <span class="title secondLine"> Request System</span>

                </a>
                <a *ngIf="!securityGroup && !reportsAdmin" routerLink='/home' [routerLinkActive]="['active']" id='home-tour'><em class="material-icons" 
                                title="Home">home</em><span class="icon-text">Home</span></a><br *ngIf="!securityGroup && !reportsAdmin">
                <a *ngIf="!securityGroup && !reportsAdmin" routerLink='/manage-shipping' [class.active]="isLinkActive('manage-shipping')" (click)="getFavorites('manage-shipping')"  id='manage-requests-tour'><em class="material-icons" 
                                title="Manage Requests">shopping_cart</em><span class="icon-text">
                                Manage
                                Requests</span></a><br *ngIf="!securityGroup && !reportsAdmin">
                <a *ngIf='(this.isManagerCheck() || roleType=="super admin shipping group") && !securityGroup && !reportsAdmin' (click)="getFavorites('manage-approval')" routerLink='/manage-approvals/all' [class.active]="isLinkActive('manage-approvals')" id='manage-approvals-tour'><em
                                class="material-icons" title="Manage Approval">playlist_add_check</em><span
                                class="icon-text">Manage
                                Approval</span></a><br *ngIf='(this.isManagerCheck() || roleType=="super admin shipping group") && !securityGroup && !reportsAdmin'>
                <a *ngIf='(roleType=="shipping group" || roleType=="super admin shipping group" || scrapShipping) && !securityGroup && !reportsAdmin' (click)="getFavorites('shipping-approval')" routerLink='/shipping/all' [class.active]="isLinkActive('shipping')" id='shipping-approvals-tour'><em class="material-icons"
                        title="Shipping Approval">task</em><span class="icon-text">
                        Shipping Approval
                        </span></a><br *ngIf='roleType=="shipping group" || roleType=="super admin shipping group"'>
                <a *ngIf="!securityGroup || reportsAdmin" routerLink='/reports' [routerLinkActive]="['active']" (click)="getFavorites('reports')" id='reports-tour'><em
                                class="material-icons" title="Reports">storage</em><span
                                class="icon-text">Reports</span></a><br *ngIf="!securityGroup && !reportsAdmin">
                <a *ngIf="!securityGroup && !reportsAdmin" routerLink='/maintain-address-master' [routerLinkActive]="['active']" id='address-master-tour'><em class="material-icons"
                                title="Address Master">email</em><span class="icon-text">Address
                                Master</span></a><br *ngIf="!securityGroup && !reportsAdmin">
                <a *ngIf="!reportsAdmin" routerLink='/help-support' [routerLinkActive]="['active']" id='help-support-tour'><em class="material-icons"
                                title="Help and Support">support</em><span class="icon-text">Help and
                                Support</span></a><br *ngIf="!securityGroup && !reportsAdmin">
                <a *ngIf="(securityGroup || roleType=='super admin shipping group') && !reportsAdmin" routerLink='/edit-status' [routerLinkActive]="['active']"><em class="material-icons" 
                        title="Exit from Gate">admin_panel_settings</em><span class="icon-text">Exit from Gate</span></a>

        </div>
</div>
<div id="main" class="h-100">
        <section class="header-section d-flex" *ngIf="this.plantCode && this.userId && this.userName">
                <span *ngIf="!mini" class="material-icons arrow-icon d-none d-sm-flex"
                        (click)="toggleSidebar()">keyboard_arrow_left</span>
                <span *ngIf="mini" class="material-icons arrow-icon d-none d-sm-flex"
                        (click)="toggleSidebar()">keyboard_arrow_right</span>
                <app-header class="header flex-grow-1"></app-header>
        </section>
        <section class="body-section">
                <router-outlet ></router-outlet>
        </section>
        <section class="row footer-section" *ngIf="this.plantCode && this.userId && this.userName">
                <app-footer class="position-fixed bottom-0"></app-footer>
        </section>
</div>
