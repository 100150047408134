import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService, private toastrService: ToastrService, private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url != 'https://web-services.oanda.com/rates/api/v2/rates/candle.json?base=JPY&quote=USD&api_key=kmagilavy'
            && !request.url.includes('https://expressentry.melissadata.net/web/') && request.url !='https://api.exchangerate-api.com/v4/latest/JPY') {
            const token: string = localStorage.getItem('token');
            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: token
                    }
                });
            }
        }
        if (localStorage.getItem('MSRS_IDTOKEN')) {
            const id_token = JSON.parse(localStorage.getItem('MSRS_IDTOKEN'));
            var userId = id_token['Workday Id'];
            var userName = id_token.GivenName + ' ' + id_token.Surname;
        }

        if ((localStorage.getItem('plantCode') == null || (localStorage.getItem('MSRS_IDTOKEN') && !userId || !userName)) && !request.url.includes('oauth2/v2.0/token')) {
            window.location.hash = '#/unauthorized'
            this.router.navigateByUrl('/unauthorized')
            this.spinner.hide();
        }
        else {
            return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    this.spinner.hide()
                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                        this.toastrService.error(errorMessage)
                    }
                    if (error.status.toString() == '401') {
                        localStorage.clear();
                        sessionStorage.clear();
                        const redirectUrl = environment.redirectURI;
                        this.toastrService.info("Token expired, redirecting to login page again.");
                        setTimeout(() => {
                            window.location.href = redirectUrl;
                        }, 2000);
                    }
                    return throwError(error);
                }));
        }
    }
}