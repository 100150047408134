<nav class="navbar justify-content-start flex-nowrap main-bar flex-column-reverse flex-sm-row">
    <!-- <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbar2">
        <span class="navbar-toggler-icon"></span>
    </button> -->
    <div class="nav-bread">
        <a class="bread-crumbs" *ngIf="showTitleMenu();then singleMenu else doubleMenu"></a>
        <ng-template #singleMenu>
            <a class="bread-crumbs">
                <span class="pointer text-uppercase" *ngIf="!securityGroup && !reportsAdmin" routerLink="/home">MSRS
                </span> <em *ngIf="!securityGroup && !reportsAdmin" class="material-icons arrowIcon pt-1">keyboard_arrow_right</em>
                <span class="activePage text-uppercase">{{pathnames[0]}}</span>
            </a>
        </ng-template>
        <ng-template #doubleMenu>
            <a class="bread-crumbs">
                <span class="pointer text-uppercase" routerLink="/home">Home</span>
                <em class="material-icons arrowIcon pt-1">keyboard_arrow_right</em>
                <span class="pointer text-uppercase" [routerLink]="[pathnames[0].path]">{{pathnames[0].title}}</span>
                <em class="material-icons arrowIcon pt-1" *ngIf='pathnames[1].title!=null'>keyboard_arrow_right</em>
                <span class="activePage text-uppercase" *ngIf='pathnames[1].title!=null'>{{pathnames[1].title}}</span>
            </a>
        </ng-template>
    </div>
    <ul class="navbar-nav flex-row ml-auto">
        <li *ngIf="!securityGroup && !reportsAdmin" class="nav-item" [routerLinkActive]="['active']" (click)="startTour()">
            <em class="nav-link material-icons main-link" title="Help">help_outline</em>
        </li>
        <li *ngIf="!securityGroup && !reportsAdmin" class="nav-item dropdown" [routerLinkActive]="['active']">
            <mat-icon matBadge="{{notificationLength}}" matBadgeHidden="{{showMatBadge}}" matBadgeColor="warn"
                class="nav-link material-icons main-link" id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="false" aria-expanded="true" title="Notifications">notifications_none</mat-icon>
            <div *ngIf="dropDownArray.length==0;then noNotification else showNotification"></div>
            <ng-template #showNotification>
                <div class="dropdown-menu position-absolute" aria-labelledby="navbarDropdownMenuLink">
                    <div class="scrollNotify">
                        <a class="dropdown-item" style="font-size:0.8rem;font-weight: 500;"
                            title="Click to view the request"
                            *ngFor='let notify of dropDownArray' (click)="updateNotification('single',notify)">
                            <span class="material-icons notifyIcon">notifications_none</span>
                            <span *ngIf="notify.event_id == 5;then activity else noActivity"></span>
                            <ng-template #activity>
                            <span>{{notify.request_id}} has been logged with comments</span>
                            </ng-template>
                            <ng-template #noActivity>
                            <span>{{notify.event_comments}}</span>
                            </ng-template>
                        </a>
                    </div>
                    <a class="dropdown-item menu-footer text-center" data-toggle="modal" data-target="#notification"
                        data-dismiss="modal" (click)="getNotifications('view')">View all
                        notifications</a>
                </div>
            </ng-template>
            <ng-template #noNotification>
                <div class="dropdown-menu noNotify position-absolute" aria-labelledby="navbarDropdownMenuLink">
                    <div style="color: #cc0000;font-size: 1rem;font-weight: 500;">No New Notifications</div>
                    <a class="dropdown-item menu-footer text-center" data-toggle="modal" data-target="#notification"
                        data-dismiss="modal" (click)='getNotifications("view")'>View all
                        notifications</a>
                </div>
            </ng-template>
        </li>
        <li class="nav-item dropdown" [routerLinkActive]="['active']">
            <em class="nav-link material-icons main-link pr-0" id="userProfileDropDown" data-toggle="dropdown"
            aria-haspopup="false" aria-expanded="true" title="User Profile">perm_identity</em>
            <div class="dropdown-menu profileDropDown position-absolute" aria-labelledby="userProfileDropDown">
                    <div class="material-icons text-center profileIcon">account_circle</div>
                    <div class="text-center userName">{{user_name}}</div>
                    <div class="text-center workdayId">{{workday_id}}</div>
                    <div class="text-center jobTitle">{{plantCode}}</div>
                    <hr>
                    <div *ngIf="manualPlantCodes.length != 0" class="manualPlant">
                        <div class="text-center manualPlantTitle">Switch user plant</div>
                        <div class="text-center jobTitle manualPlantCode" *ngFor="let role of manualPlantCodes" (click)="switchPlant(role)">
                            {{role}}
                        </div>
                    </div>
                    <hr *ngIf="manualPlantCodes.length != 0" class="below">
                    <div class="w-100 text-center">
                    <button type="button" class="logout-button" (click)="logout()">Logout</button>
                </div>
            </div>
        </li>
    </ul>
</nav>


<div class="modal fade text-dark" id="notification" tabindex="-1" role="dialog" aria-hidden="true"
    data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header py-2">
                <div class="modal-title"><em class="material-icons matIconColor">notifications_none</em>
                    <span class="ml-2"> All Notifications</span>
                </div>
            </div>
            <div class="modal-body px-0 py-0">
                <div class="container-fluid">
                    <ngx-spinner [name]="spinner1" bdOpacity=0.9 bdColor="rgba(51,51,51,0.87)" size="medium"
                        color="white" type="timer" [fullScreen]="false">
                        <p style="color: white"> Loading... </p>
                    </ngx-spinner>
                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <div class="notification-section">
                                <div class="notification-content">
                                    <div class="notification-note">
                                        Please Note: Notifications older than 30 days will be auto-deleted.
                                    </div>
                                    <div class="search-section d-flex" *ngIf = "!noRecord">
                                        <mat-form-field style="width: 57%;">
                                            <input [(ngModel)]='searchKey' autocomplete="off"
                                                (keyup)="updateFilter($event)" name='searchKey' placeholder="Search"
                                                matInput />
                                            <mat-icon matSuffix class="fs-5 search-icon">search</mat-icon>
                                        </mat-form-field>
                                        <button *ngIf="!noRecord" type="button" class="next-button ml-auto" style="height: 90%;"
                                            (click)="updateNotification('all','')">Mark All As Read</button>
                                    </div>
                                    <mat-card class="notification-card my-1" *ngFor='let notify of displayArr'
                                        data-dismiss="modal" [ngClass]="{'isRead': notify.is_read == 'Y'}"
                                        (click)="updateNotification('single',notify)">
                                        <div>
                                            <span class="material-icons notify-icon text-center">notifications</span>
                                            <span class="notify-title">{{notify.event_name}}</span>
                                            <div class="notify-desc">{{notify.event_comments}} - {{notify.eventtime}}
                                            </div>
                                        </div>
                                    </mat-card>
                                    <div *ngIf="noRecord" class="text-center h4 pt-3 text-danger">
                                        No record(s) found
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="next-button mx-auto" data-dismiss="modal" (click)="this.searchKey=''">Close</button>
            </div>
        </div>
    </div>
</div>