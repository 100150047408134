import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from 'src/app/auth-guard/auth-guard'
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'maintain-address-master',
    loadChildren: () => import('./address-master/address-master.module').then(m => m.AddressMasterModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-approvals/:status',
    loadChildren: () => import('./manage-approvals/manage-approvals.module').then(m => m.ManageApprovalsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-approvals',
    redirectTo: '/manage-approvals/all',
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'shipping/:status',
    loadChildren: () => import('./shipping/shipping.module').then(m => m.ShippingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'shipping',
    redirectTo: '/shipping/all',
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-shipping',
    loadChildren: () => import('./manage-shipping/manage-shipping.module').then(m => m.ManageShippingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'help-support',
    loadChildren: () => import('./help-support/help-support.module').then(m => m.HelpSupportModule) ,
    canActivate: [AuthGuard],
  },
  {
    path: 'view-request/:reqNumber',
    loadChildren: () => import('./view-request/view-request.module').then(m => m.ViewRequestModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'print-page',
    loadChildren: () => import('./print-page/print-page.module').then(m => m.PrintPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'edit-status',
    loadChildren: () => import('./edit-status/edit-status.module').then(m => m.EditStatusModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true , preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
