import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { HomeService } from '../home/home.service';
import * as $ from 'jquery';
import { CommonService } from '../common-service/common-service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],

})
export class MenuComponent implements OnInit {

  showMenuIcons = false
  mini = true;
  roleType: any;
  favorites = false;
  screenName: any;
  userId :any;
  userName:any;
  plantCode = '';
  isManager = false;
  securityGroup:boolean;
  reportsAdmin:boolean = false;
  scrapShipping:boolean;

  constructor(public router: Router,private commonService: CommonService, private readonly spinner: NgxSpinnerService, private readonly toastrService: ToastrService, private homeService: HomeService) {
    this.securityGroup = this.commonService.getSecurityGroup();
    // this.reportsAdmin = this.commonService.getReportsAdmin();
    this.scrapShipping = this.commonService.getScrapShipping();
    window.addEventListener('resize',(event)=>{
      let width = $(window).width();
      if(width < 576){
        this.mini = false;
        this.toggleSidebar();
      }
      else{
        this.mini = false;
        this.toggleSidebar();
      }
    })
  }

  ngOnInit(): void {
    let id_token = JSON.parse(localStorage.getItem('MSRS_IDTOKEN'));
    let access_token = JSON.parse(localStorage.getItem('MSRS_ACCESSTOKEN'));
    this.userId = id_token['Workday Id'];
    this.userName = id_token.GivenName + ' ' + id_token.Surname;
    this.commonService.isEligibleForApprove(this.userId).subscribe((response: any) => {
      localStorage.setItem('isManager',JSON.stringify(response.payload[0].status))
    }, err => {
      if (!(err.includes('401'))) {
      this.toastrService.error('Unable to get manager Information', 'Failed')
      }
    })
    let val = JSON.parse(localStorage.getItem('isManager'));
    this.isManager = (val === 'true')
    this.roleType = localStorage.getItem('roleType');
    this.plantCode = localStorage.getItem('plantCode');
  }
  toggleSidebar() {
    if (this.mini) {
      document.getElementById("mySidebar").style.width = "265px";
      document.getElementById("main").style.marginLeft = "195px";
      this.mini = false;
    } else {
      document.getElementById("mySidebar").style.width = "65px";
      document.getElementById("main").style.marginLeft = "2px";

      this.mini = true;
    }
  }

  isLinkActive(url): boolean {
    const value = this.router.url.split('/');
    if (localStorage.getItem('previousUrl')) {
      let path = localStorage.getItem('previousUrl')
      var previousPath = path.split('/')[1]
    }
    if (value[1] == 'view-request') {
      return url == previousPath.split("?")[0]
    }
    else {
      return url === value[1].split("?")[0];
    }
  }
  getFavorites(screenName) {
    this.homeService.getFavorites(this.userId, this.plantCode).subscribe((response: any) => {
      console.log(response.payload[0])
      let result = response.payload[0]
      console.log(this.router.url)
      this.screenName = screenName
    }, err => {
      if (!(err.includes('401'))) {
      this.toastrService.error('Unable to get favorites', 'Failed')
      }
    })
  }
  addOrRemoveFav(currentPath) {
    this.spinner.show()
    console.log(currentPath);
    let favObj =
    {
      "favoritesId": 2,
      "plantCode": this.plantCode,
      "userId": this.userId,
      "myRequests": true,
      "createNewShippingRequest": true,
      "reports": true,
      "manageApproval": false,
      "shippingApproval": true,
      "createOnBehalfOfRequest": false,
      "addAddress": true,
      "createdBy": this.userName
    }
    this.homeService.updateFavorites(favObj).subscribe((response: any) => {
      console.log(response.payload[0])
      this.spinner.hide()
    }, err => {
      if (!(err.includes('401'))) {
      this.spinner.hide()
      this.toastrService.error('Unable to update favorites', 'Failed')
      }
    })
  }
  isManagerCheck():boolean{
    let val = JSON.parse(localStorage.getItem('isManager'));
    this.isManager = (val === 'true')
    return this.isManager;
  }
}
