import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { CommonService } from './common-service/common-service';
import jwt_decode from 'jwt-decode';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  showFavourites: boolean = false;

  codeResponse = '';
  id_token = '';
  access_token = '';
  refresh_token = '';
  displayMenu = false;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    sessionStorage.clear();
  }
  // idle variables
  idleState = `Not Started`;
  timedOut = false;
  lastPinged: Date = null;
  idleStart = false;

  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if (this.idleStart) {
      this.reset();
    }
  }
  constructor(private router: Router, private readonly spinner: NgxSpinnerService, private commonService: CommonService, private readonly idle: Idle, private readonly keepalive: Keepalive, private readonly toastrService: ToastrService) {
    router.events.subscribe(async event => {
      if (event instanceof NavigationStart) {
        if (window.location.href.indexOf('code') != -1) {
          this.spinner.show();
          const url = window.location.href.split('?')[1]
          if (url.indexOf('error') != -1) {
            console.log("inside second if confn", this.codeResponse);
            window.location.hash = '#/unauthorized'
            this.router.navigateByUrl('/unauthorized')
            this.spinner.hide()
          }
          else {
            this.codeResponse = window.location.href.split('?')[1].split('code=')[1].split('&')[0];
            localStorage.setItem('codeResponse', this.codeResponse)
            var details = {
              'grant_type': environment.grantType,
              'client_id': environment.clientId,
              'code': this.codeResponse,
              'redirect_uri': environment.redirectURI,
              'code_verifier': environment.codeVerifier
            };
            var formBody = [];
            for (var property in details) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            let body = formBody.join("&");
            let response = await this.commonService.getTokens(body).then((res: any) => {
              if (res != null) {
                this.id_token = res.id_token;
                this.access_token = res.access_token;
                this.refresh_token = res.refresh_token;
                localStorage.setItem('token', this.id_token)
                localStorage.setItem('Refresh_token', this.refresh_token)
                setInterval(() => {
                  this.refreshToken();
                }, 3300000);
                if (this.id_token != null || this.access_token != null) {
                  var decodedIDToken = jwt_decode(this.id_token);
                  var decodedACCESSToken = jwt_decode(this.access_token);
                  localStorage.setItem('MSRS_IDTOKEN', JSON.stringify(decodedIDToken));
                  localStorage.setItem('MSRS_ACCESSTOKEN', JSON.stringify(decodedACCESSToken));
                  let roleType = decodedIDToken['roles']
                  if(roleType.includes('MSRS.Requestor'))
                  localStorage.setItem('roleType', 'requestor');
                  if(roleType.includes('MSRS.Scrap.Shipping')) {
                    localStorage.setItem('roleType', 'requestor');
                    localStorage.setItem('isScrap', 'scrap shipping');
                    this.commonService.setSecurityGroup(false);
                    this.commonService.setReportsAdmin(false);
                    this.commonService.setScrapShipping(true);
                  }
                  if(roleType.includes('MSRS.Shipping')){
                    localStorage.setItem('isScrap', '');
                    this.commonService.setScrapShipping(false);
                    localStorage.setItem('roleType', 'shipping group');

                  }
                  if(roleType.includes('MSRS.Admin')) {
                    localStorage.setItem('isScrap', '');
                    this.commonService.setScrapShipping(false);
                    localStorage.setItem('roleType', 'super admin shipping group');
                  }
                  // if(roleType.includes('MSRS.Reports.Admin')) {
                  //   localStorage.setItem('roleType', 'reports admin');
                  //   this.commonService.setReportsAdmin(true);
                  //   this.commonService.setSecurityGroup(false);
                  // }
                  if(roleType.includes('MSRS.Security.Admin')) {
                    localStorage.setItem('roleType', 'security group');
                    this.commonService.setSecurityGroup(true);
                    this.commonService.setReportsAdmin(false);
                    this.commonService.setScrapShipping(false);
                  }
                  if (localStorage.getItem('roleType') == undefined || localStorage.getItem('roleType') == null) {
                    localStorage.setItem('roleType', 'requestor');
                  }
                  let enrollGroup = roleType.filter(role => {
                    return role.toLowerCase().indexOf('enrolled') != -1;
                  });
                  if (enrollGroup.length > 0) {
                    let actualPlantCodeArr = [];
                    actualPlantCodeArr = enrollGroup.filter(role => {
                      return role.toLowerCase().indexOf('.m.') == -1;
                    });
                    if (actualPlantCodeArr.length == 0) { //if not actual plantcode 
                      let plantCode = enrollGroup[0].split('.');
                      if (plantCode.length == 4) {
                        localStorage.setItem('plantCode', plantCode[1])
                      }
                      else {
                        localStorage.setItem('plantCode', 'TTC')
                      }
                    }
                    else { //if actual plantcode 
                      let plantCode = actualPlantCodeArr[0].split('.');
                      if (plantCode.length == 3)
                        localStorage.setItem('plantCode', plantCode[1])
                      // localStorage.setItem('plantCode', 'TMMI')
                      else
                        localStorage.setItem('plantCode', 'TTC')
                    }
                  }
                  else {
                    window.location.hash = '#/unauthorized'
                    this.router.navigateByUrl('/unauthorized')
                    this.spinner.hide()
                  }
                }
              }
            },err=>{
                this.toastrService.error('Token is expired/invalid, redirecting to the login page', 'Failed');
                setTimeout(() => {
                  localStorage.clear();
                  sessionStorage.clear();
                  window.location.href = environment.redirectURI;
                }, 2000)
            })
          }
        }
      }
      this.displayMenu = localStorage.getItem('roleType') ? true : false;
    })

    this.idle.setIdle(1800);
    this.idle.setTimeout(100);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });
    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.idleState = `Timed Out`;
      sessionStorage.clear();
      localStorage.clear();
      setTimeout(() => {
        window.location.href = environment.redirectURI;
      }, 500)
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = `You have gone idle`;
      $('#idleModal').modal('show');
      this.idleStart = true;
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = `You are idle. Do something! \' Your session will get refreshed in ${countdown} seconds`
    })
    this.keepalive.interval(5);
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started';
    this.timedOut = false;
    this.idleStart = false;
    $('#idleModal').modal('hide');
  }

  toggleFavourites() {
    this.showFavourites = !this.showFavourites;
    console.log(this.showFavourites)
  }
  async refreshToken() {
    console.log('inside refresh token')
    let data = {
      'grant_type': "refresh_token",
      'client_id': environment.clientId,
      'refresh_token': localStorage.getItem('Refresh_token')
    };
    let refreshBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      refreshBody.push(encodedKey + "=" + encodedValue);
    }
    let body = refreshBody.join("&");
    console.log("body in refersh time", body);
    let response = await this.commonService.getTokens(body).then((res: any) => {
      console.log("response in refresh token", res);
      if (res != null) {
        this.id_token = res.id_token
        this.access_token = res.access_token
        this.refresh_token = res.refresh_token
        localStorage.setItem('token', this.id_token)
        localStorage.setItem('Refresh_token', this.refresh_token)
      }
    })
  }
}


