import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../common-service/common-service';
import { HeaderService } from './header.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';
import * as introJs from 'intro.js/intro.js';
import { ToastrService } from 'ngx-toastr';

declare interface RouteInfo {
  path: string;
  title: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/home', title: 'Home' },
  { path: '/maintain-address-master', title: 'Address Master' },
  { path: '/add-address', title: 'Add Address' },
  { path: '/edit-address', title: 'Edit Address' },
  { path: '/view-address/id', title: 'View Address' },
  { path: '/reports', title: 'Reports' },
  { path: '/old-ttc-reports', title: 'Archived' },
  { path: '/manage-shipping', title: 'Manage Requests' },
  { path: '/create-new-request', title: 'Create New Shipping Request' },
  { path: '/view-request', title: 'View Request' },
  { path: '/manage-approvals', title: 'Manage Approval' },
  { path: '/help-support', title: 'Help and Support' },
  { path: '/print-page', title: 'Print Page' },
  { path: '/notification', title: 'Notifications' },
  { path: '/shipping', title: 'Shipping Approval' },
  { path: '/edit-status', title: 'Exit from Gate' },
];
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  pathnames: any = [];
  private minibar: boolean;
  plantCode = '';
  manualPlantCodes = [];
  // userId = 107056
  userId: any;
  // userId = 417846;
  notificationArray = []
  dropDownArray = []
  searchKey = ''
  filteredNotification: any = [];
  noRecord = false;
  displayArr: any[] = [];
  currentUrl = '';
  previousUrl = '';
  previousTitle: any
  notificationLength = 0;
  showMatBadge = true;
  spinner1 = 'spinner';
  id_token: any;
  access_token: any;
  user_name = '';
  workday_id = '';
  job_title = '';
  introJS = introJs();
  securityGroup:boolean;
  reportsAdmin:boolean = false;
  
  constructor(location: Location, public element: ElementRef, public router: Router, private commonService: CommonService, private headerService: HeaderService, private readonly spinner: NgxSpinnerService, private readonly toastrService: ToastrService) {
    this.securityGroup = commonService.getSecurityGroup();
    // this.reportsAdmin = commonService.getReportsAdmin();
    this.location = location;
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        localStorage.setItem('previousUrl', this.previousUrl)
        this.currentUrl = event.url;
      };
    });
  }

  ngOnInit(): void {
    this.id_token = JSON.parse(localStorage.getItem("MSRS_IDTOKEN"));
    this.access_token = JSON.parse(localStorage.getItem("MSRS_ACCESSTOKEN"));
    this.user_name = this.id_token.GivenName + ' ' + this.id_token.Surname;
    this.workday_id = this.id_token['Workday Id'];
    this.job_title = this.id_token['Job title'];
    this.userId = this.workday_id;
    this.assignPlantGroup();
    setInterval(() => { this.getNotifications('N'); }, 300000);
    this.minibar = false;
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.pathnames = this.getTitle();
    const navbar: HTMLElement = this.element.nativeElement;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pathnames = this.getTitle();
      }
    })
  }
  assignPlantGroup() {
    this.plantCode = localStorage.getItem('plantCode');
    let roleType = this.id_token['roles'];
    let enrollGroup = roleType.filter(role => {
      return role.toLowerCase().indexOf('enrolled') != -1;
    });
    let manualPlantCodeArr = [];
    for (let i = 0; i < enrollGroup.length; i++) {
      manualPlantCodeArr[i] = enrollGroup[i].split('.')[1];
    }
    manualPlantCodeArr = manualPlantCodeArr.filter(role => {
      return role != this.plantCode;
    });
    this.manualPlantCodes = manualPlantCodeArr;
    this.getNotifications('N');
  }
  switchPlant(role) {
    localStorage.setItem('plantCode', role);
    this.assignPlantGroup();
    let currentUrl = this.router.url;
    console.log(currentUrl);
    this.clearDataCache();
    if (currentUrl == '/home') {
      window.location.reload();
      this.router.navigate(['home'], { skipLocationChange: true });
    }
    else {
      this.router.navigate(['/home'])
        .then(() => {
          window.location.reload();
        });
    }
  }

  clearDataCache() {
    localStorage.removeItem('Favorites-Cache');
    localStorage.removeItem('Reports-Cache');
    localStorage.removeItem('My-Requests-Cache');
    localStorage.removeItem('My-Team-Requests-Cache');
    localStorage.removeItem('My-Approvals-Count-Cache');
    localStorage.removeItem('My-Team-Approvals-Cache');
    localStorage.removeItem('My-Approvals-Cache');
    localStorage.removeItem('My-Team-Approvals-Cache')
    localStorage.removeItem('Shipping-Cache');
    localStorage.removeItem('Shipping-Count-Cache');
    localStorage.removeItem('Address-Master-Cache');
  }

  startTour() {
    var path = this.location.prepareExternalUrl(this.location.path());
    let screenSteps = [];
    let steps = [
      {
        element: 'intro',
        title: "Welcome to Miscellaneous Shipping Request System!",
        intro: "Let us walk you through the important features in this application. In case you wish to skip, please press close(x) or else to continue, press 'Next'.",
        position: 'right',
        step: 1
      },
      {
        element: document.querySelectorAll('#home-tour')[0],
        title: "Home",
        // intro: "This is your home page. You will be able to view your favorites and your saved reports.",
        intro: "Main page of the application. Access to Module features, shortcuts, report template, and more.",
        position: 'right'
      },
      {
        element: document.querySelectorAll('#manage-requests-tour')[0],
        title: "Manage Requests",
        //intro: "Displays your requests and your team's requests.",
        intro: "Manage shipping request such as create, view, update, cancel and more.",
        position: 'right',
        step: '2'
      },
      {
        element: document.querySelectorAll('#manage-approvals-tour')[0],
        title: "Manage Approvals",
        //intro: "Displays the list of approved, rejected and pending requests for your approval.",
        intro: "Manage approval of the shipping request i.e. Approve/Reject.",
        position: 'right'
      },
      {
        element: document.querySelectorAll('#shipping-approvals-tour')[0],
        title: "Shipping Approval",
        //intro: "Displays the list of approved requests pending for the shipping approval.",
        intro: "Module for Shipping Group to process the shipping items.",
        position: 'right'
      },
      {
        element: document.querySelectorAll('#reports-tour')[0],
        title: "Reports",
        intro: "Generate reports for shipping request.",
        position: 'right'
      },
      {
        element: document.querySelectorAll('#address-master-tour')[0],
        title: "Address Master",
        intro: "Maintain customer name, address, and contact information in Address book.",
        position: 'right'
      },
      {
        element: document.querySelectorAll('#help-support-tour')[0],
        title: "Help and Support",
        intro: "Provide supporting documents and links.",
        position: 'right'
      },
      {
        element: document.querySelectorAll('#navbarDropdownMenuLink')[0],
        title: "Notifications",
        intro: "Notify user if activities is performed for a created shipping request.",
        position: 'bottom'
      },
      {
        element: document.querySelectorAll('#userProfileDropDown')[0],
        title: "Users Login",
        intro: "Users general information.",
        position: 'bottom'
      }
    ];
    if (path.includes('home')) {
      screenSteps = [
        {
          element: document.querySelectorAll('#favorites-tour')[0],
          title: "My Favorites",
          //intro: "List of your added favorites. By clicking, you will be able to navigate to that particular screen.",
          intro: "Shortcut of the application feature.",
          position: 'bottom'
        },
        {
          element: document.querySelectorAll('#saved-reports-tour')[0],
          title: "My Reports",
          //intro: "List of your saved reports. By clicking, you will be able to view that particular report.",
          intro: "User defined/created report templates.",
          position: 'right'
        },
        // {
        //   element: document.querySelectorAll('#add-favorite-tour')[0],
        //   title: "My Favorites",
        //   intro: "Add/Remove your favorites",
        //   position: 'left'
        // }
      ]
    }
    else if (path.includes('manage-shipping')) {
      screenSteps = [
        {
          element: document.querySelectorAll('#my-requests-tour')[0],
          title: "Manage Requests",
          intro: "Manage shipping request created by me.",
          position: 'right'
        },
        {
          element: document.querySelectorAll('#my-team-requests-tour')[0],
          title: "Manage Requests",
          intro: "View shipping request created by my team.",
          position: 'right'
        },
        {
          element: document.querySelectorAll('#create-new-tour')[0],
          title: "Manage Requests",
          intro: "Create a new shipping request.",
          position: 'right'
        },
        {
          element: document.querySelectorAll('#create-behalf-tour')[0],
          title: "Manage Requests",
          intro: "Create a new shipping request for my team.",
          position: 'right'
        },
        // {
        //   element: document.querySelectorAll('#search-table-tour')[0],
        //   title: "Manage Requests",
        //   intro: "Search grid.",
        //   position: 'right'
        // },
        // {
        //   element: document.querySelectorAll('#sort-tour')[0],
        //   title: "Manage Requests",
        //   intro: "Click here to sort requests based on selected column.",
        //   position: 'right'
        // },
        // {
        //   element: document.querySelectorAll('#filter-tour')[0],
        //   title: "Manage Requests",
        //   intro: "Click here to filter requests based on selected search criteria.",
        //   position: 'right'
        // },
        // {
        //   element: document.querySelectorAll('#download-tour')[0],
        //   title: "Manage Requests",
        //   intro: "Click here to download request excel.",
        //   position: 'right'
        // },
      ]
    }
    else if (path.includes('manage-approvals')) {
      screenSteps = [
        {
          element: document.querySelectorAll('#my-approvals-tour')[0],
          title: "Manage Approvals",
          intro: "Shipping request approve/reject by me.",
          position: 'right'
        },
        {
          element: document.querySelectorAll('#my-team-approvals-tour')[0],
          title: "Manage Approvals",
          intro: "Shipping request approve/reject by my team.",
          position: 'right'
        },
        // {
        //   element: document.querySelectorAll('#status-filter-tour')[0],
        //   title: "Manage Approvals",
        //   intro: "Choose status filter.",
        //   position: 'right'
        // },
        // {
        //   element: document.querySelectorAll('#date-filter-tour')[0],
        //   title: "Manage Approvals",
        //   intro: "Choose date filter.",
        //   position: 'left'
        // },
        // {
        //   element: document.querySelectorAll('#download-tour')[0],
        //   title: "Manage Approvals",
        //   intro: "Click here to export.",
        //   position: 'left'
        // }
      ]
    }
    else if (path.includes('shipping')) {
      // screenSteps = [
      //   {
      //     element: document.querySelectorAll('#status-filter-tour')[0],
      //     title: "Shipping Approvals",
      //     intro: "Click here to filter requests based on status.",
      //     position: 'right'
      //   },
      //   {
      //     element: document.querySelectorAll('#download-tour')[0],
      //     title: "Shipping Approvals",
      //     intro: "Click here to export.",
      //     position: 'left'
      //   },
      //   {
      //     element: document.querySelectorAll('#dates-created-tour')[0],
      //     title: "Shipping Approvals",
      //     intro: "Choose date filter.",
      //     position: 'left'
      //   },
      //   {
      //     element: document.querySelectorAll('#item-type-tour')[0],
      //     title: "Shipping Approvals",
      //     intro: "Choose item type filter.",
      //     position: 'left'
      //   },
      //   {
      //     element: document.querySelectorAll('#and-or-tour')[0],
      //     title: "Shipping Approvals",
      //     intro: "Select And or condition.",
      //     position: 'left'
      //   },
      //   {
      //     element: document.querySelectorAll('#apply-filter-tour')[0],
      //     title: "Shipping Approvals",
      //     intro: "Click to fetch results.",
      //     position: 'left'
      //   },
      //   {
      //     element: document.querySelectorAll('#date-filter-tour')[0],
      //     title: "Shipping Approvals",
      //     intro: "Choose date filter.",
      //     position: 'left'
      //   }
      // ]
    }
    else if (path.includes('reports')) {
      // screenSteps = [
      //   {
      //     element: document.querySelectorAll('#report-name-tour')[0],
      //     title: "Reports",
      //     intro: "Enter your report name here.",
      //     position: 'right'
      //   },
      //   {
      //     element: document.querySelectorAll('#report-action-tour')[0],
      //     title: "Reports",
      //     intro: "Click here to save your report.",
      //     position: 'left'
      //   },
      //   {
      //     element: document.querySelectorAll('#report-search-tour')[0],
      //     title: "Reports",
      //     intro: "Enter your search criteria in this section.",
      //     position: 'left'
      //   },
      //   {
      //     element: document.querySelectorAll('#show-report-tour')[0],
      //     title: "Reports",
      //     intro: "Click here to show results.",
      //     position: 'left'
      //   },
      //   {
      //     element: document.querySelectorAll('#reset-report-tour')[0],
      //     title: "Reports",
      //     intro: "Click here to reset search criteria.",
      //     position: 'left'
      //   }
      // ]
    }
    else if (path.includes('maintain-address-master')) {
      screenSteps = [
        {
          element: document.querySelectorAll('#add-address-tour')[0],
          title: "Address Master",
          intro: "Save new customer information in Address Book.",
          position: 'bottom'
        },
        // {
        //   element: document.querySelectorAll('#export-address-tour')[0],
        //   title: "Address Master",
        //   intro: "Click here to export.",
        //   position: 'left'
        // }
      ]
    }

    steps = [...steps, ...screenSteps];
    this.introJS.setOptions({
      steps: steps,
      showBullets: true,
      tooltipClass: 'customTooltip'
    }).start();
  }

  getTitle() {
    // titlee contains the current URL of the page
    var arr = [];
    console.log(this.location.path())
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (!titlee) {
      titlee = '/home'
    }
    if (titlee.charAt(0) === '#') {
      //titlee conatins only the name from the current URL
      titlee = titlee.slice(1);
    }
    console.log('titlee before assign', titlee, titlee.split('/')[2])
    if (titlee.split('/')[1].includes('manage-shipping') && titlee.split('/')[2] != 'create-request') {
      titlee = '/manage-shipping';
      console.log('titlee after assign', titlee)
    }
    else if (titlee.split('/')[1] === 'manage-approvals') {
      titlee = '/manage-approvals';
    }
    else if (titlee.split('/')[1] === 'shipping') {
      titlee = '/shipping';
    }
    else if (titlee.split('/')[1].includes('maintain-address-master') && !(titlee.split('/')[2])) {
      titlee = '/maintain-address-master';
    }
    else if (titlee.split('/')[1].includes('reports') && titlee.split('/')[2] != 'old-ttc-reports'){
      titlee = '/reports';
    }
    if (!titlee.split('/')[1].includes('reports')) {
      this.commonService.setReportDetails({});
    }

    for (const i of this.listTitles) {
      if (i.path === titlee) {
        arr.push(i.title);
        return arr;
      }
    }

    var pathname = titlee.split('/');
    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === `/${pathname[1]}`) {
        var obj = {
          'path': this.listTitles[item].path,
          'title': this.listTitles[item].title
        }
        if (`/${pathname[2]}` === '/edit-address') {
          var obj1 = {
            'path': '',
            'title': this.listTitles[item + 2].title
          }
        }
        else if (`/${pathname[2]}` === '/view-address') {
          var obj1 = {
            'path': '',
            'title': this.listTitles[item + 3].title
          }
        }
        
        else if (`/${pathname[1]}` === '/view-request') {
          for (let i = 0; i < this.listTitles.length; i++) {
            if (this.previousUrl == this.listTitles[i].path) {
              this.previousTitle = this.listTitles[i].title
            }
            else if (this.previousUrl.split('/')[1] === this.listTitles[i].path.substring(1)) {
              this.previousTitle = this.listTitles[i].title;
            }
          }
          if (this.previousTitle == undefined) {
            this.previousTitle = 'My Requests'
            this.previousUrl = "/manage-shipping"
            localStorage.setItem('previousUrl', this.previousUrl)
          }
          var obj1 = {
            'path': this.previousUrl,
            'title': this.previousTitle
          }
        }
        else {
          var obj1 = {
            'path': '',
            'title': this.listTitles[item + 1].title
          }
        }
        if (`/${pathname[1]}` === '/view-request') {
          arr.push(obj1);
          arr.push(obj);
        }
        else {
          arr.push(obj);
          arr.push(obj1);
        }
        return arr;
      }
    }
    return 'Shipment Details';
  }
  showTitleMenu() {
    if (this.pathnames.length === 1) {
      return true;
    } else {
      return false;
    }
  }
  minibarToggle() {
    const body = document.getElementsByTagName('body')[0];
    const sidebarMini = 'sidebar-mini';
    if (!this.minibar) {
      body.classList.add(sidebarMini);
      this.minibar = true;
    } else {
      body.classList.remove(sidebarMini);
      this.minibar = false;
    }
  }

  updateFilter(event) {
    let val = event.target.value.toLowerCase();
    this.filteredNotification = [];
    for (const i of this.notificationArray) {
      const a = this.searchKey.toLowerCase();
      const regex1 = RegExp(a);
      if ((regex1.test(i.event_comments.toLowerCase())) || (regex1.test(i.request_id.toLowerCase())) || (regex1.test(i.eventtime.toLowerCase()))) {
        this.filteredNotification.push(i);
      }
    }
    if (this.filteredNotification.length) {
      this.noRecord = false;
      this.displayArr = this.filteredNotification;
      this.displayArr = [...this.displayArr]
    } else {
      this.displayArr = [];
      this.noRecord = true;
    }

  }
  getNotifications(type) {
    if (type == 'N') {
      this.headerService.getNotification(this.plantCode, this.userId, 'N').subscribe((response: any) => {
        if (response.payload[0] != null) {
          this.dropDownArray = response.payload
          this.dropDownArray = [...this.dropDownArray]
          this.notificationLength = this.dropDownArray.length
          if (this.notificationLength > 0) {
            this.showMatBadge = false
          }
        }
        else {
          this.dropDownArray = []
        }
      }, err => {
        // if (err.includes('401')) {
        //   this.toastrService.error('Token is expired, redirecting to the login page.', 'Failed');
        //   setTimeout(() => {
        //     localStorage.clear();
        //     sessionStorage.clear();
        //     window.location.href = environment.redirectURI;
        //   }, 2000)
        // }
      })
    }
    else if (type == 'view') {
      this.spinner.show(this.spinner1);
      this.headerService.getNotification(this.plantCode, this.userId, '').subscribe((response: any) => {
        if (response.payload[0] != null) {
          this.notificationArray = response.payload
          this.displayArr = this.notificationArray
          this.displayArr = [...this.displayArr]
        }
        else {
          this.notificationArray = []
          this.displayArr = []
          this.displayArr = [...this.displayArr]
          this.noRecord = true
        }
        this.spinner.hide(this.spinner1);
      }, err => {
        // if (err.includes('401')) {
        //   this.toastrService.error('Token is expired, redirecting to the login page.', 'Failed');
        //   setTimeout(() => {
        //     localStorage.clear();
        //     sessionStorage.clear();
        //     window.location.href = environment.redirectURI;
        //   }, 2000)
        // }
        this.notificationArray = []
        this.displayArr = []
        this.displayArr = [...this.displayArr]
        this.noRecord = true
        this.spinner.hide(this.spinner1);
      })
    }
  }

  updateNotification(type, notify) {
    console.log(notify)
    if (type == 'all' && notify == '') {
      let notifyObj = {
        "userId": this.userId,
        "plantCode": this.plantCode
      }
      console.log(notifyObj)
      this.headerService.updateNotification(notifyObj).subscribe((response: any) => {
        this.getNotifications('view')
        console.log(response)
      })
    }
    else {
      let currentUrl = '/view-request/' + notify.request_id
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
      let notifyObj = {
        "userId": this.userId,
        "plantCode": this.plantCode,
        "activityId": notify.activity_id,
      }
      this.headerService.updateNotification(notifyObj).subscribe((response: any) => {
        this.getNotifications('N');
        console.log(response)
      })
    }
  }
  logout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = environment.logoutUrl;
  }
}
