<div class="activityStreamDiv">
    <div class="title">
        <span class="material-icons">track_changes</span>
        <div>Activity Stream</div>
        <div class="ml-auto" (click)="close()"><span class="material-icons">close</span></div>
    </div>
    <div class="body" id="message-body">
        <div *ngFor="let i of messages">
            <div class="messages">
                <div class="name">{{i.createdby}}</div>
                <div class="value" *ngIf="i.eventname == 'Comment has been logged'">{{requestId}} has been added with
                    comments</div>
                <div class="value">
                    <span *ngIf="i.eventname == 'Comment has been logged' && i.eventdescription[0][0] != '@';">
                        <span class="comment">Comment</span> :
                        {{i.eventdescription[0]}}
                    </span>
                    <span *ngIf="i.eventname == 'Comment has been logged' && i.eventdescription[0][0] == '@';">
                        <span class="onbehalf">
                            {{i.eventdescription[0][0] != '@' ? i.eventdescription[0] : i.eventdescription[0].slice(1)}}
                        </span> :
                        {{i.eventdescription[1]}}
                    </span>
                    <span *ngIf="i.eventname != 'Comment has been logged'">
                        {{i.eventdescription[0]}}
                    </span>
                </div>
                <div class="date">{{i.eventtime}}</div>
            </div>
        </div>
        <div class="loader" *ngIf="showLoader">
            <ngx-spinner [name]="spinner1" bdColor="rgba(0,0,0,0)" size="default" color="red" type="ball-elastic-dots"
                [fullScreen]="false">
            </ngx-spinner>
        </div>
    </div>
    <div class="footer" *ngIf="screenName !='reports'">
        <textarea  id="commentBox" (keypress)="omit_pipe_char($event)" (paste)="onPasteRestriction($event)" rows="4" cols="49" placeholder="Additional comments" [(ngModel)]="comments"
            (ngModelChange)="filterBehalfOf($event)"></textarea>
        <div class="company-suggestion bg-light" *ngIf='userList.length!=0'>
            <div *ngFor="let user of userList;let i=index;" (click)='onSelectBehalfOf(user)'>
                {{user.userid}} - {{user.name}} | {{user.locationdescription}}
            </div>
        </div>
        <div class="mt-2 d-flex flex-row-reverse"><button (click)="post()" class="next-button"
                [disabled]="comments.length == 0" data-dismiss="modal">Post</button></div>
    </div>
</div>