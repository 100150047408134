import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/Rx';
import { environment } from '../../environments/environment';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class ManageApprovalsService {
  getMyApprovalsURL = environment.shippingURL + "manageApprovalSummary?plantCode=";
  getManageApprovalCountURL = environment.shippingURL + "getManageApprovalCount?plantCode=";
  ApproveRequestsURL = environment.shippingURL + "updateRequestStatus";
  addBulkActivityStreamURL = environment.notificationURL + "addBulkActivityStream"
  getMyTeamApprovalsURL = environment.shippingURL + "myTeamApprovalSummary?plantCode=";
  getManageTeamApprovalCountURL = environment.shippingURL + "getMyTeamApprovalCount?plantCode=";
  sendMailURL = environment.notificationURL + "sendMail";
  sendSubmittedMailURL = environment.notificationURL + "requestApprovalMail";
  constructor(private http: HttpClient) { }
  getMyApprovals(plantCode, userId,requestDateTime) {
    const url = this.getMyApprovalsURL+plantCode+"&approverId="+userId+"&filterCondition="+requestDateTime;
    return this.http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getMyTeamsApprovals(plantCode, userId,requestDateTime) {
    const url = this.getMyTeamApprovalsURL+plantCode+"&userId="+userId+"&filterCondition="+requestDateTime;
    return this.http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getManagerApprovalCount(plantCode, userId,requestDateTime){
    const url = this.getManageApprovalCountURL+plantCode+"&userId="+userId+"&filterCondition="+requestDateTime;
    return this.http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  getManagerTeamApprovalCount(plantCode, userId,requestDateTime){
    const url = this.getManageTeamApprovalCountURL+plantCode+"&userId="+userId+"&filterCondition="+requestDateTime;
    return this.http.get(url, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  approveRequests(approveObj){
    const url = this.ApproveRequestsURL
    return this.http.post(url,approveObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  addBulkActivityStream(activityStreamObj){
    const url = this.addBulkActivityStreamURL
    return this.http.post(url,activityStreamObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  sendMail(mailObj){
    const url = this.sendMailURL
    return this.http.post(url,mailObj, httpOptions)
      .map((response: any) => response).catch(this.handleError);
  }
  // sendSubmittedMail(mailObj){
  //   const url = this.sendMailURL
  //   return this.http.post(url,mailObj, httpOptions)
  //     .map((response: any) => response).catch(this.handleError);
  // }
  private handleError(error: HttpErrorResponse) {
    console.log("error")
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMsg = `An error occurred: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      errorMsg = `Backend returned code ${error.status}`;
    }
    // return an observable with a user-facing error message
    return throwError(errorMsg);
  }
}
