import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CreateNewRequestService } from '../manage-shipping/create-new-request/create-new-request.service';
import { ActivityStreamService } from './activity-stream.service';
import * as $ from 'jquery';
import { ManageApprovalsService } from '../manage-approvals/manage-approvals.service';
import { Subject, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'app-activity-stream',
  templateUrl: './activity-stream.component.html',
  styleUrls: ['./activity-stream.component.scss']
})
export class ActivityStreamComponent implements OnInit {

  @Input() requestId: string;
  @Input() approverId: string;
  @Input() screenName: string;
  @Output() closeDialog = new EventEmitter<boolean>();
  messages: any[] = [];
  comments: string = '';
  userList: any[] = [];
  onBehalf: any;
  spinner1 = 'sp1';
  userId: any;
  userName: any;
  plantCode: any ;
  showLoader: boolean = true;
  onBehalfSelected: boolean = false;
  email_id: any;
  modelChanged = new Subject<string>();
  getBehalfOfSubscription: any;

  close() {
    this.closeDialog.emit(false);
  }
  constructor(private activityStreamService: ActivityStreamService, private createNewRequestService: CreateNewRequestService, private spinner: NgxSpinnerService, private readonly toastrService: ToastrService, private manageApprovalsService: ManageApprovalsService) { }

  ngOnInit(): void {
    let id_token = JSON.parse(localStorage.getItem('MSRS_IDTOKEN'));
    let access_token = JSON.parse(localStorage.getItem('MSRS_ACCESSTOKEN'));
    this.userId = id_token['Workday Id'];
    this.userName = id_token.GivenName + ' ' + id_token.Surname;
    this.email_id = access_token.unique_name;
    this.plantCode = localStorage.getItem('plantCode');
    this.spinner.show(this.spinner1);
    this.activityStreamService.getActivityStream(this.requestId).subscribe((response: any) => {
      console.log(response.payload);
      if (response.payload[0] != null) {
        this.messages = response.payload;
        this.messages.forEach(message => {
          console.log(message.eventdescription.split(":")[0]);
          message.eventdescription = message.eventdescription.split(":");
        })
        this.scrollDown();
      }
      this.showLoader = false;
    },err=>{
      this.showLoader = false;
    })

    this.modelChanged.debounceTime(500).distinctUntilChanged().subscribe(value => {
      this.userList = [];
      if (value != null && value != '') {
        if (value.length > 0 && value.charAt(0) == '@' && value != '@') {
          var split = value.indexOf("@");
          if (split != -1) {
            value = value.substring(split + 1);
            this.onBehalfSelected = true;
          }
          else {
            this.onBehalfSelected = false;
          }
          value = decodeURI(value);
          if (value.length > 2) {
            this.getBehalfOfSubscription = this.createNewRequestService.getBehalfOfUsers(value,this.userId).subscribe(
              response => {
                this.userList = response.payload;
              },
              err => {
                console.log('Error: ' + err)
              }
            );
          }
        }
        else {
          this.userList = [];
        }
      }
      else {
        this.comments = '';
      }
   });
  }

  post() {
    this.showLoader = true;
    this.scrollDown();
    if (this.onBehalfSelected) {
      this.comments = '@' + this.comments;
    }
    this.onBehalfSelected = false;
    let comments = this.comments;
    let behalfOf = this.onBehalf ? ',' + this.onBehalf.userid + 'N' : ''
    let streamObj = {
      "requestId": ["'"+this.requestId+"'"],
      "createdBy": this.userName,
      "userId": this.userId,
      "plantCode": this.plantCode,
      "eventId": 5,
      "eventComments": comments.toString().replace(/'/g,'`'),
      "notificationUserId": '{' + this.userId + 'N' + behalfOf + '}',
      "lastUpdatedBy": this.userName
    }
    console.log('insert activity stream obj', streamObj)
    this.activityStreamService.addActivityStream(streamObj).subscribe((response: any) => {
      if (behalfOf) {
        let comment = this.comments.split(':')
        this.sendMail(comment[1]);
      }
      this.comments = '';
      this.ngOnInit();
    }, err => {
      this.toastrService.error('Unable to add activity stream', 'Failed');
    })
  }

  scrollDown() {
    setTimeout(() => {
      var elem = document.getElementById('message-body');
      elem.scrollTop = elem.scrollHeight;
    }, 100);
  }

  filterBehalfOf(event) {
     this.modelChanged.next(event);
  }

  onSelectBehalfOf(user) {
    this.getBehalfOfSubscription.unsubscribe();
    this.userList = [];
    this.comments = user.name + " : ";
    this.onBehalf = user;
    console.log(this.onBehalf)
    $("#commentBox").focus();
  }
  sendMail(comments) {
    let mailObj = {
      "requestorName": this.userName,
      "tagName": this.onBehalf.name,
      "toAddress": this.onBehalf.emailid,
      "ccAddress":this.email_id,
      "requestId": this.requestId,
      "requestorId": this.userId,
      "approverId": this.approverId,
      "status": "Activity Stream",
      "comments": comments,
      "isBulkInsert": 'N'
    }
    console.log(mailObj);
    this.manageApprovalsService.sendMail(mailObj).subscribe((response: any) => {
      console.log(response)
    }, err => {
      this.toastrService.error('Unable to send Mail', 'Failed')
    })
  }
  omit_pipe_char(event) {
    var k = event.keyCode;
    return k != 124 && k != 39 && k != 58;
  }
  onPasteRestriction(e) {
    var regex = /[|':\s]/i;
    var key = e.clipboardData.getData('text');
    for (let i = 0; i < key.length; i++) {
      if (regex.test(key[i])) {
        e.preventDefault();
        return false;
      }
    }
  }
}
