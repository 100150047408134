<div class='container-fluid h-100' *ngIf="displayMenu">
    <app-menu class="h-100"></app-menu>
</div>
<div class="modal fade" id="idleModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false"
    data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row text-center">
                        <div class="col-md-12 py-3 px-0 col-lg-12">
                                {{idleState}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>