import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common-service/common-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  plantCode = "";
  securityGroup:boolean;
  constructor(commonService:CommonService) {
    this.securityGroup = commonService.getSecurityGroup();
   }

  ngOnInit(): void {
    this.plantCode = localStorage.getItem('plantCode')
  }
}
